<script setup>
import { ref } from "vue";
import ActorSearch from "./ActorSearch.vue";
import AddActorDialog from "./AddActorDialog.vue";
import html2canvas from "html2canvas";

const listName = ref("");
const selectedActor = ref(null);
const characters = ref([]);
const captureDiv = ref(null);

const actorSelected = (actor) => {
  selectedActor.value = actor;
};

const addCharacter = (character) => {
  characters.value.push(character);
  selectedActor.value = null; // Close the dialog after adding the character
};

const closeDialog = () => {
  selectedActor.value = null; // Close the dialog when the close-dialog event is emitted
};

const removeCharacter = (characterId) => {
  characters.value = characters.value.filter(
    (character) => character.actor.id !== characterId
  );
};

const proxyImageUrl = (path) => {
  if (!path) return "";

  const baseUrl = process.env.VUE_APP_PROXY_URL;
  const imageUrl = `https://media.themoviedb.org/t/p/w600_and_h900_bestv2${path}`;

  if (process.env.NODE_ENV === "development") {
    return imageUrl;
  } else {
    return `${baseUrl}?url=${encodeURIComponent(imageUrl)}`;
  }
};

const captureToImage = () => {
  const removeIcons = document.querySelectorAll(".remove-icon");
  for (const icon of removeIcons) {
    icon.style.display = "none"; // Hide all remove icons before capturing
  }

  html2canvas(captureDiv.value, {
    useCORS: true,
  })
    .then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = `${listName.value}-fantasy-cast.png`;
      link.click();
      for (const icon of removeIcons) {
        icon.style.display = "block"; // Hide all remove icons before capturing
      }
    })
    .catch((error) => {
      console.error("Capture Error:", error);
    });
};
</script>

<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="px-2">
        <p-input-text
          type="text"
          v-model="listName"
          placeholder="Movie or Book Name"
        />
      </div>
      <div class="px-2">
        <ActorSearch @actor-selected="actorSelected" />
      </div>
      <AddActorDialog
        :visible="!!selectedActor"
        :actor="selectedActor"
        @add-character="addCharacter"
        @close-dialog="closeDialog"
      />
    </div>
    <div
      id="actor-list"
      ref="captureDiv"
      class="row justify-content-center mt-5"
    >
      <p-card id="actor-cards-container" width="500">
        <template #title>{{ listName }}</template>
        <template #content>
          <div class="row justify-content-center g-1">
            <p-card
              class="actor-card"
              v-for="character in characters"
              :key="character.actor.id"
            >
              <template #header>
                <i
                  class="remove-icon pi pi-times"
                  @click="removeCharacter(character.actor.id)"
                ></i>
                <img
                  alt="Actor Image"
                  width="150"
                  class="rounded mx-auto d-block mt-3"
                  :src="proxyImageUrl(character.actor.profile_path)"
                />
              </template>
              <template #title>
                <h1>
                  {{ character.characterName }}
                </h1>
              </template>
              <template #subtitle>
                played by {{ character.actor.name }}
              </template>
            </p-card>
          </div>
        </template>
        <template v-if="characters.length > 0" #footer>
          <div>
            <p>created using fantasycasts.com</p>
          </div>
        </template>
      </p-card>
    </div>
    <div v-if="characters.length > 0" class="row justify-content-end mb-4">
      <p-button label="Save PNG" @click="captureToImage" />
    </div>
  </div>
</template>
