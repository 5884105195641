<script>
// import { RouterLink } from 'vue-router';

</script>

<template>
    <div class="navbar navbar-expand-lg navbar-light bg-light colorful-background">
        <div class="container">
            <RouterLink to="/" class="navbar-brand">Fantasy Casts</RouterLink>
             <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <RouterLink to="/" class="nav-link">
                    <li class="nav-item">
                        Home
                    </li></RouterLink>
                    <li class="nav-item">
                        <RouterLink to="/about" class="nav-link">About</RouterLink>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style>
.nav-link {
    cursor: pointer;
}

</style>