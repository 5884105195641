<template>
  <div class="jumbotron jumbotron-fluid colorful-background">
    <div class="container">
      <h1 class="display-4">Fantasy Casts</h1>
      <p class="lead">
        Your ultimate platform to create the perfect cast for your dream movie
        or book. Choose from a vast selection of actors who have starred in your
        favorite films and bring your vision to life.
      </p>
    </div>
  </div>
</template>
