<template>
  <div class="container mt-4">
    <h1 class="text-center">About</h1>
    <p>
      I sometimes find myself disappointed with the actors that protray
      characters from books (nothing to do with the actor themselves) that I
      love but only because I imagined them differently so I made this fun
      little site to be able to share with my friends and family who I would
      have casted instead.
    </p>
    <p>This is purely for fun, so have fun!</p>
    <iframe
      src="https://giphy.com/embed/4HBlnIbbepFWegoOPP"
      width="480"
      height="254"
      style=""
      frameBorder="0"
      class="giphy-embed"
      allowFullScreen
    ></iframe>
    <p>
      <a
        href="https://giphy.com/gifs/recordingacademy-grammys-grammy-2024-4HBlnIbbepFWegoOPP"
        >via GIPHY</a
      >
    </p>
  </div>
</template>
