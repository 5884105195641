/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue";
import App from "./App.vue";
import "./assets/style.css";
import "primeicons/primeicons.css";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import Card from "primevue/card";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import router from "./router/router";


const app = createApp(App).use(router);
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      prefix: "p",
      darkModeSelector: "system",
      cssLayer: false,
    },
  },
});
app.component("PInputText", InputText);
app.component("PAutoComplete", AutoComplete);
app.component("PCard", Card);
app.component("PButton", Button);
app.component("PDialog", Dialog);
app.mount("#app");
